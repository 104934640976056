import { default as _91_46_46_46path_93MMe3seiMRhMeta } from "/opt/atlassian/pipelines/agent/build/nuxt-app/pages/[...path].vue?macro=true";
import { default as ClientErrorTestBkI0fT7UJGMeta } from "/opt/atlassian/pipelines/agent/build/nuxt-app/pages/ClientErrorTest.vue?macro=true";
import { default as ServerErrorTestAF9WI12wUKMeta } from "/opt/atlassian/pipelines/agent/build/nuxt-app/pages/ServerErrorTest.vue?macro=true";
export default [
  {
    name: "path",
    path: "/:path(.*)*",
    component: () => import("/opt/atlassian/pipelines/agent/build/nuxt-app/pages/[...path].vue").then(m => m.default || m)
  },
  {
    name: "ClientErrorTest",
    path: "/ClientErrorTest",
    component: () => import("/opt/atlassian/pipelines/agent/build/nuxt-app/pages/ClientErrorTest.vue").then(m => m.default || m)
  },
  {
    name: "ServerErrorTest",
    path: "/ServerErrorTest",
    meta: ServerErrorTestAF9WI12wUKMeta || {},
    component: () => import("/opt/atlassian/pipelines/agent/build/nuxt-app/pages/ServerErrorTest.vue").then(m => m.default || m)
  }
]
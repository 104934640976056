
import { library, config } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faTwitterSquare, faTwitch, faGithubSquare, faYoutube, faInstagram, faFacebookF } from '@fortawesome/free-brands-svg-icons'
import { faTimes, faExclamationTriangle, faCheck, faExclamationCircle, faMapMarkedAlt, faMapMarkerAlt, faTruck as fasTruck, faInfoCircle as fasInfoCircle} from '@fortawesome/pro-solid-svg-icons'
import { faChevronDown, faArrowDown, faSearch, faPlus as farPlus, faMinus as farMinus, faChevronUp, faArrowUp, faShoppingBag, faArrowTurnDownRight, faTruck as farTruck } from '@fortawesome/pro-regular-svg-icons'
import { faInfoCircle, faTimes as falTimes, faCheck as falCheck, faSlidersH, faPlus, faMinus, faTruck, faArrowLeft, faCrosshairs, } from '@fortawesome/pro-light-svg-icons'

library.add(
  faTwitterSquare,
  faTwitch,
  faGithubSquare,
  faTimes,
  faChevronDown,
  faExclamationTriangle,
  faCheck,
  faInfoCircle,
  faArrowDown,
  falTimes,
  falCheck,
  faExclamationCircle,
  faSlidersH,
  faPlus,
  faMinus,
  faYoutube,
  faInstagram,
  faMapMarkedAlt,
  faMapMarkerAlt,
  faSearch,
  faTruck,
  farPlus,
  farMinus,
  faFacebookF,
  fasTruck,
  faArrowLeft,
  faChevronUp,
  faCrosshairs,
  fasInfoCircle,
  faArrowUp,
  faArrowTurnDownRight,
  faShoppingBag,
  farTruck
)

// This is important, we are going to let Nuxt worry about the CSS
config.autoAddCss = false

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.component('fa', FontAwesomeIcon, {})
})
<template>
  <StepWrapper
    :heading="$lang('complaintResources', 'stepFour')"
    :loading="false"
    :step-activated="stepActivated"
    :current-step="activeIndex === stepIndex"
    :optional-step="true"
    :is-step-skipable="true"
    @on-next="onNext"
    @on-skip="onSkip"
    @edit-step="$emit('edit-step', stepIndex)"
  >
    <div>
      <img
        src="~assets/images/canlabel-complaint.jpg"
        class="w-full object-cover"
      />
      <div>
        <div class="base-medium-text mt-24">
          {{ $lang('complaintResources', 'optionalExplainer') }}
        </div>
        <div class="preamble-text mt-32">
          {{ $lang('complaintResources', 'canlabelExplanation') }}
        </div>
        <div class="pt-8">
          <div class="flex items-center base-medium-text text-grey600">
            <div
              class="w-16 h-16 mr-8 rounded-full border border-grey600 flex justify-center items-center small-base-regular-text text-grey800"
            >
              1
            </div>
            {{ $lang('complaintResources', 'canlabelStepOne') }}
          </div>
          <div class="flex items-center base-medium-text text-grey600">
            <div
              class="w-16 h-16 mr-8 rounded-full border border-grey600 flex justify-center items-center small-base-regular-text text-grey800"
            >
              2
            </div>
            {{ $lang('complaintResources', 'canlabelStepTwo') }}
          </div>
          <div class="flex items-center base-medium-text text-grey600">
            <div
              class="w-16 h-16 mr-8 rounded-full border border-grey600 flex justify-center items-center small-base-regular-text text-grey800"
            >
              3
            </div>
            {{ $lang('complaintResources', 'canlabelStepThree') }}
          </div>
        </div>
      </div>
      <div class="border border-grey600 rounded bg-grey200 mt-24">
        <TextInput
          :id="'complaintCanLabel'"
          :placeholder="$lang('complaintResources', 'canLabelPlaceholder')"
          :form-input="true"
          :error="inputError"
          :max-length="1"
          :init-value="input"
          @submitInput="onInput"
        />
      </div>
    </div>
  </StepWrapper>
</template>
<script lang="ts">
import TextInput from '../form-elements/TextInput.vue';
import StepWrapper from './StepWrapper.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  components: { StepWrapper, TextInput },
  props: {
    activeIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stepActivated: false,
      stepIndex: 3,
      input: '',
      inputError: '',
    };
  },
  watch: {
    activeIndex() {
      if (this.stepIndex === this.activeIndex) {
        this.stepActivated = true;
      }
    },
  },
  methods: {
    onNext() {
      if (/^[0-9]+$/.test(this.input)) {
        this.$emit('on-next', {
          machineNumber: this.input,
        });
      } else {
        this.inputError = this.$lang(
          'complaintResources',
          'genericValidationError'
        );
      }
    },
    onInput(input: string) {
      this.input = input;
      this.inputError = '';
    },
    onSkip() {
      this.$emit('on-next');
    },
  },
});
</script>

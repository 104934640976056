<template>
  <div id="site_header" class="sticky top-0 z-header">
    <div v-if="isLoggedInAsImpersonator || isImpersonating" class="impersonator-tab text-center text-white">
      <p v-if="isLoggedInAsImpersonator" class="p-12">Impersonator</p>
      <p v-else class="p-12">Impersonating</p>
    </div>
    <div v-if="hasMarketUsps" class="bg-black">
      <MarketUsps :usps="marketUsps" :is-mobile="isMobile" :is-tablet="isTablet ? true : false"></MarketUsps>
    </div>
    <div v-if="config.siteMessages && config.siteMessages.length > 0">
      <SiteMessage
        v-for="(message, idx) in config.siteMessages"
        :key="idx"
        :site-message="message"
      />
    </div>
    <div
      class="header relative z-20"
      :class="{ 'header--take-over': takeOverHeader && !forceHideTakeOver }"
    >
      <div
        v-if="
          isTablet || isMobile &&
          headerConfig &&
          headerConfig.mainMenu &&
          (headerConfig.mainMenu.length > 0 || headerConfig.mainMenuContent)
        "
        :class="{
          'z-headerIcon': !hasMarketUsps || (hasMarketUsps && !overlayActive),
          'invisible z-10': hasMarketUsps && overlayActive,
        }"
        class="menu-bars absolute top-0 h-80 flex items-center md:hidden"
        @click="onClickMenu"
      >
        <div class="pl-24 pr-16 py-24 select-none cursor-pointer">
          <div
            class="bar1"
            :class="{
              'bar1--open bg-grey900': overlayActive,
              'bg-white': takeOverHeader && !forceHideTakeOver,
              'bg-grey900': !takeOverHeader || forceHideTakeOver,
            }"
          ></div>
          <div
            class="bar2"
            :class="{
              'bar2--open bg-grey900': overlayActive,
              'bg-white': takeOverHeader && !forceHideTakeOver,
              'bg-grey900': !takeOverHeader || forceHideTakeOver,
            }"
          ></div>
        </div>
      </div>
      <div
        :class="
          headerConfig && headerConfig.mainMenu.length > 0 || headerConfig && headerConfig.mainMenuContent
            ? 'pl-64'
            : 'pl-24'
        "
        class="md:layout-container md:mx-auto h-full pr-24 flex justify-between items-center w-full"
      >
        <div class="md:w-1/4 flex">
          <nuxt-link :to="config.currentMarket?.url || '/'">
            <img
              v-if="config.theme === 'thunder'"
              src="~/assets/images/logo-thunder-inverted.png"
              class="h-32 cursor-pointer object-contain select-none"
              height="25"
              width="140"
              alt="Thunder logo"
            />
            <img
              v-else-if="takeOverHeader && !forceHideTakeOver"
              src="~/assets/images/logo-niqo-co-white.svg"
              class="h-24 cursor-pointer object-contain select-none object-left"
              height="25"
              width="100"
              alt="Niqo Co logo"
            />
            <img
              v-else
              src="~/assets/images/logo-niqo-co-black.svg"
              class="h-24 cursor-pointer object-contain select-none object-left"
              height="25"
              width="100"
              alt="Niqo Co logo"
            />
          </nuxt-link>
        </div>
        <div
          v-if="!isMobile && headerConfig && headerConfig.mainMenuContent && headerConfig.mainMenuContent.length"
          class="w-2/4 justify-center hidden md:flex"
        >
          <div
            v-for="menuItem in headerConfig.mainMenuContent"
            :key="menuItem.id"
            class="header-link mr-24 lg:mr-32 last:mr-0 small-base-text text-grey900 font-graphik relative py-30"
            :class="{ 'header-link--children': menuItem.children }"
            @mouseover="menuItem.children ? (showOverlay = true) : false"
            @mouseleave="showOverlay = false"
          >
            <nuxt-link
              v-if="menuItem.href"
              :to="menuItem.href"
              :title="menuItem.text"
            >
              {{ menuItem.text }}
            </nuxt-link>
            <div v-else class="inline-block cursor-default">
              {{ menuItem.text }}
            </div>
            <svg
              v-if="menuItem.children"
              class="ml-8 pt-px text-sm h-16 w-16 inline-block overflow-visible align-[-0.125em]"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
              :fill="takeOverHeader && !forceHideTakeOver || config.theme === 'thunder' ? '#fff' : ''"
            >
              <path d="M239 401c9.4 9.4 24.6 9.4 33.9 0L465 209c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0l-175 175L81 175c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9L239 401z"/>
            </svg>
            <div
              v-if="menuItem.children"
              class="submenu opacity-0 pointer-events-none p-32 pr-56 bg-white border border-grey500 absolute top-0 left-1/2 transform -translate-x-1/2 mt-80 transition-all duration-200 ease-in-out"
            >
              <div class="flex">
                <div
                  v-for="menuChildren in menuItem.children"
                  :key="menuChildren.id"
                  class="mr-48 last:mr-0"
                >
                  <div
                    v-if="menuChildren.heading"
                    class="text-tiny uppercase mb-12 leading-xs font-medium text-grey800 font-calibre"
                  >
                    {{ menuChildren.heading }}
                  </div>
                  <nuxt-link
                    v-for="child in menuChildren.links"
                    :key="child.id"
                    :to="child.href"
                    :title="child.text"
                    class="submenu-item block font-medium mb-8 text-sm leading-base text-gray900 last:mb-0"
                  >
                    {{ child.text }}
                  </nuxt-link>
                </div>
              </div>
            </div>
          </div>
        </div> 
        <div
          v-else-if="
            !isMobile && headerConfig &&
            headerConfig.mainMenuContent &&
            headerConfig.mainMenuContent.length
          "
          class="w-2/4 justify-center hidden md:flex"
        >
          <nuxt-link
            v-for="menuItem in headerConfig.mainMenu"
            :key="'mainMenuDesktop-' + menuItem.text"
            :to="menuItem.href"
            :target="menuItem.target"
            :title="menuItem.title"
            class="header-link mr-32 last:mr-0 small-base-text text-grey900 font-graphik"
          >
            {{ menuItem.text }}
          </nuxt-link>
        </div>
        <div class="flex justify-end items-center flex-1 md:w-1/4">
          <div
            v-if="showImpersonate"
            class="md:ml-16 ml-20 lg:ml-24 select-none cursor-pointer text-grey900"
            @click="onOpenImpersonateModal"
          >
            <svg
              v-if="takeOverHeader && !forceHideTakeOver || config.theme === 'thunder'"
              xmlns="http://www.w3.org/2000/svg"
              class="h-20 w-20 text-white overflow-visible"
              fill="none"
              viewBox="0 0 20 20"
              stroke="white"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
            <svg
              v-else
              xmlns="http://www.w3.org/2000/svg"
              class="h-20 w-20 text-grey900"
              fill="none"
              viewBox="0 0 24 24"
              stroke="#1a1414"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"
              />
            </svg>
          </div>
          <div
            class="md:ml-16 ml-20 lg:ml-24 select-none cursor-pointer text-grey900 open-search"
            @click="openSearch"
          >
            <img
              v-if="takeOverHeader && !forceHideTakeOver || config.theme === 'thunder'"
              src="~/assets/icons/search-white.svg"
              class="h-20 w-20 text-white"
              height="20"
              width="20"
              alt="icon-search"
            />
            <img
              v-else
              src="~/assets/icons/search-dark.svg"
              class="h-20 w-20 text-grey900"
              height="20"
              width="20"
              alt="icon-search"
            />
          </div>
          <div
            v-if="(currentMarket && currentMarket.enablePurchases || currentMarket && currentMarket.samplingEnabled) && hasMyAccountPage"
            class="md:ml-16 ml-20 lg:ml-24 select-none cursor-pointer text-grey900"
            @click="onOpenUserModal"
          >
            <img
              v-if="takeOverHeader && !forceHideTakeOver || config.theme === 'thunder'"
              src="~/assets/icons/user-white.svg"
              class="h-20 w-20 text-white"
              height="20"
              width="20"
              alt="icon-search"
            />
            <img
              v-else
              src="~/assets/icons/user-dark.svg"
              class="h-20 w-20 text-grey900"
              height="20"
              width="20"
              alt="icon-user"
            />
          </div>
          <div
            v-if="currentMarket && currentMarket.enablePurchases"
            ref="subscriptionBtn"
            class="md:ml-16 ml-20 lg:ml-24 flex justify-center items-center text-grey900 select-none cursor-pointer relative"
            @click="openBasket(PurchaseType.Subscription)"
          >
            <img
              v-if="takeOverHeader && !forceHideTakeOver || config.theme === 'thunder'"
              src="~/assets/icons/refresh-white.svg"
              class="h-20 w-20 text-white"
              height="20"
              width="20"
              alt="icon-refresh"
            />
            <img
              v-else
              src="~/assets/icons/refresh-dark.svg"
              class="h-20 w-20 text-grey900"
              height="20"
              width="20"
              alt="icon-refresh"
            />
            <div v-if="subscriptionBasketQuantity > 0" class="basket-indicator">
              {{ subscriptionBasketQuantity }}
            </div>
          </div>
          <div
            v-if="currentMarket && currentMarket.enablePurchases"
            ref="basketBtn"
            class="md:ml-16 ml-20 lg:ml-24 flex justify-center items-center text-grey900 select-none cursor-pointer relative"
            @click="openBasket(PurchaseType.Standard)"
          >
            <img
              v-if="takeOverHeader && !forceHideTakeOver || config.theme === 'thunder'"
              src="~/assets/icons/cart-white.svg"
              class="h-20 w-20 text-white"
              height="20"
              width="20"
              alt="icon-cart"
            />
            <img
              v-else
              src="~/assets/icons/cart-dark.svg"
              class="h-20 w-20 text-grey900"
              height="20"
              width="20"
              alt="icon-cart"
            />
            <div v-if="basketQuantity > 0" class="basket-indicator">
              {{ basketQuantity }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="menu-hover-overlay fixed w-full bg-black bg-opacity-75 left-0 top-0 opacity-0 transition-all duration-200 ease-in-out pointer-events-none"
      :class="{ 'opacity-100': showOverlay }"
    ></div>
    <Overlay
      class="z-overlay"
      :overlay-active="overlayActive"
      @close-overlay="closeOverlay"
    >
      <transition name="slide">
        <div
          v-if="showContent"
          class="fixed top-0 left-0 bottom-0 px-24 pt-80 pb-56 max-w-320 w-full bg-white flex flex-col justify-between overflow-y-auto"
        >
          <div
            v-if="isMobile && overlayActive"
            class="menu-bars absolute top-0 z-headerIcon h-80 flex items-center md:hidden"
            @click="closeOverlay"
          >
            <div class="pr-16 py-24 select-none cursor-pointer">
              <div class="bar1 bar1--open bg-grey900"></div>
              <div class="bar2 bar2--open bg-grey900"></div>
            </div>
          </div>
          <div class="flex flex-col">
            <div v-if="headerConfig && headerConfig.mainMenuContent">
              <div
                v-for="menuItem in headerConfig.mainMenuContent"
                :key="'mainMenuMobile-' + menuItem.text"
                class="mb-24"
              >
                <MainMenuItem :menu-item="menuItem" />
              </div>
            </div>
            <div v-else>
              <div
                v-for="menuItem in headerConfig.mainMenu"
                :key="'mainMenuMobile-' + menuItem.text"
                class="sub-heading-lg-text mb-20 cursor-pointer select-none"
                @click="closeOverlay"
              >
                <nuxt-link
                  :to="menuItem.href"
                  :target="menuItem.target"
                  :title="menuItem.title"
                >
                  {{ menuItem.text }}
                </nuxt-link>
              </div>
            </div>
            <div class="mt-20">
              <MenuFoldOut
                v-for="subMenu in headerConfig.subMenu"
                :id="'subMenuMobileHeader' + subMenu.heading"
                :key="'subMenuMobileHeader' + subMenu.heading"
                :heading="subMenu.heading"
                :toggled-row="toggledMenuRow"
                :items="subMenu.links"
                :is-mobile="isMobile"
                @toggleMenuRow="toggleMenuRow"
                @close-overlay="closeOverlay"
              />
            </div>
          </div>
          <div class="pt-48">
            <div v-if="config.markets && config.markets?.length > 1 && config.theme !== 'thunder'" class="flex">
              <ClientOnly>
                <MarketDisplay />
              </ClientOnly>
            </div>
            <div
              v-for="metaLink in headerConfig.metaLinks"
              :key="'metaMenuHeaderItem-' + metaLink.text"
              class="mb-16 cursor-pointer select-none"
              @click="closeOverlay"
            >
              <nuxt-link
                :to="metaLink.href"
                :target="metaLink.target"
                :title="metaLink.title"
                class="small-base-text text-grey600 small-base-regular-text"
              >
                {{ metaLink.text }}
              </nuxt-link>
            </div>
          </div>
        </div>
      </transition>
    </Overlay>
    <Transition name="fade-modal" class="z-20 relative">
      <UserModal v-if="showUserModal" @close-modal="onCloseUserModal" />
    </Transition>
    <SearchForm
      :show="showSearchForm"
      @close="showSearchForm = !showSearchForm"
    />
    <ImpersonateModal
      v-if="showImpersonateModal"
      @close-modal="onCloseImpersonateModal"
    />
    <TestModeBanner
      v-if="testModeActive && config.isLoggedInAsAdmin"
      @deactivate-test-mode-click="$emit('deactivate-test-mode-click')"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useGlobalContentStore } from '~/store/globalContent';
import { useCartStore } from '~/store/cart';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import { PurchaseType } from '~/models/api-types';
import PopUpTypes from '~/constants/pop-up-types';
import MarketUsps from '~/components/body/MarketUsps.vue';
import MarketDisplay from '~/components/MarketDisplay.vue';
import MenuFoldOut from '~/components/body/MenuFoldOut.vue';
import MainMenuItem from '~/components/header/MainMenuItem.vue';
import SiteMessage from '~/components/body/SiteMessage.vue';
import UserModal from '~/components/modals/UserModal.vue';
import ImpersonateModal from '~/components/modals/ImpersonateModal.vue'
import Overlay from '~/components/body/Overlay.vue';
import TestModeBanner from '~/components/header/TestModeBanner.vue';
import SearchForm from '~/components/header/SearchForm.vue';

const globalContentStore = useGlobalContentStore();
const config = useGlobalContentStore().config;
const cart = useCartStore();
const headerConfig = useGlobalContentStore().header;
const currentMarket = useGlobalContentStore().currentMarket;
const marketUsps = currentMarket?.marketUsps || [];
const hasMarketUsps = marketUsps.length > 0;

const uiStore = useUiStore();
const { isMobile } = storeToRefs(uiStore);
const router = useRouter();

const overlayActive = ref(false);
const showContent = ref(false);
const showSearchForm = ref(false);
const showImpersonateModal = ref(false);
const showUserModal = ref(false);
const forceHideTakeOver = ref(false);
const showOverlay = ref(false);
const toggledMenuRow = ref('');
const dismissedMessages = ref([]);
const dismissedMessagesCookies = useCookie('dismissed-sitemessages');

defineProps<{
  testModeStatus?: boolean,
  takeOverHeader?: boolean,
  testModeActive?: boolean,
  isMobile?: boolean,
  isTablet?: boolean,
}>();

const isLoggedInAsImpersonator= computed(() => {
  return config.isLoggedInAsImpersonator;
});
const isImpersonating = computed(() => {
  return config.isImpersonating;
});

const showImpersonate = computed(() => {
  return (
    config.isLoggedInAsAdmin ||
    config.isLoggedInAsImpersonator ||
    config.isImpersonating
  );
});

const basketQuantity = computed(() => {
  if (cart.items) {
    return cart.items.length;
  } 
  return 0;
});

const subscriptionBasketQuantity = computed(() => {
  if (cart.subscriptionItems) {
    return cart.subscriptionItems.length;
  } else {
    return 0;
  }
});

const hasMyAccountPage = computed(() => {
  return config.currentMarket?.myAccountPageUrl !== null;
});

onMounted(async() => {
  const isCheckoutConfirmationPage =
    config.page?.contentType?.includes('CheckoutConfirmationPage') === true;
    
  if (!isCheckoutConfirmationPage && config.currentMarket?.enablePurchases) {
    setTimeout(async() => {
      await cart.fetchCart();
    }, 0);
  }
  setOffsets();
  setupTakeOverObserver();
  window.addEventListener('resize', resize, { passive: true });
});

onBeforeUnmount(()=> {
  window.removeEventListener('resize', resize);
});

const subscriptionBtn = ref();
const basketBtn = ref();
const setOffsets = () => {
  if (subscriptionBtn.value && basketBtn.value) {
    const subscriptionBtnElementCenter =
      subscriptionBtn.value.offsetWidth / 2 + 26;
    const basketBtnElementCenter = basketBtn.value.offsetWidth / 2 + 26;
    globalContentStore.setOffsets({
      basketOffset: basketBtn.value.offsetLeft + basketBtnElementCenter,
      subscriptionOffset:
        subscriptionBtn.value.offsetLeft + subscriptionBtnElementCenter,
    });
  }
};

const resize = () => {
  setOffsets();
};

const setupTakeOverObserver = () => {
  const observer = new IntersectionObserver(
    (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          forceHideTakeOver.value = false;
        } else {
          forceHideTakeOver.value = true;
        }
      });
    },
    { rootMargin: '-100px 0px 0px 0px' }
  );
  setTimeout(() => {
    const element = document.querySelector('.take-over-block-bg');
    if (element) {
      observer?.observe(element as Element);
    }
  }, 500);
};

setOffsets();

const onClickMenu = () => {
  if (overlayActive.value) {
    closeOverlay();
  } else {
    openOverlay();
  }
};
const openOverlay = () => {
  overlayActive.value = true;
  document.body.classList.add('locked');
  setTimeout(() => {
    showContent.value = true
  }, 200);
};
const closeOverlay = () => {
  showContent.value = false;
  document.body.classList.remove('locked');
  setTimeout(() => (overlayActive.value = false), 200);
};
const toggleMenuRow = (id: string) => {
  if (toggledMenuRow.value === id) {
    toggledMenuRow.value = '';
  } else {
    toggledMenuRow.value = id;
  }
};
const { $event } = useNuxtApp();
const openBasket = (purchaseType: PurchaseType) => {
  const popUpType =
    purchaseType === PurchaseType.Standard
      ? PopUpTypes.Basket
      : PopUpTypes.Subscription;
  
  $event('open-pop-up', {
    type: popUpType,
    data: {},
  })
};
const onOpenUserModal = () => {
  if (config.isLoggedIn) {
    router.push({ path: config.currentMarket?.myAccountPageUrl || '/'} );
  } else {
    showUserModal.value = true;
    $event('close-pop-up');
  }
};
const onCloseUserModal = () => {
  document.body.classList.remove('locked');
  showUserModal.value = false;
};
const openSearch = () => {
  showSearchForm.value = true;
  $event('close-pop-up');
};
const onOpenImpersonateModal = () => {
  showImpersonateModal.value = true;
  $event('close-pop-up');
};
const onCloseImpersonateModal = () => {
  document.body.classList.remove('locked');
  showImpersonateModal.value = false;
};

// close menu when route change
const route = useRoute();
watch(
  () => route.fullPath,
  () => {
    closeOverlay();
    onCloseUserModal();
    setupTakeOverObserver();
  }
);
</script>

<style>
.header {
  @apply h-80 bg-white w-full border-b border-grey500;

  backdrop-filter: blur(30px);
  transition: background 0.3s ease;

  &--take-over {
    @apply bg-transparent border-b-0;

    backdrop-filter: none;

    .header-link {
      @apply text-white;
    }
  }
}
.menu-bars {
  * {
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
  }
}

.bar1 {
  width: 20px;
  height: 2px;
  margin-bottom: 8px;
  transition: 0.2s ease-out;
}
.bar2 {
  width: 20px;
  height: 2px;
  transition: 0.2s ease-out;
}
.bar1--open {
  -webkit-transform: rotate(-45deg) translate(-4px, 3px);
  transform: rotate(-45deg) translate(-4px, 3px);
}
.bar2--open {
  -webkit-transform: rotate(45deg) translate(-4px, -3px);
  transform: rotate(45deg) translate(-4px, -3px);
}

.slide-enter-active {
  transition: all 0.3s ease;
}
.slide-leave-active {
  transition: all 0.3s;
}
.slide-enter,
.slide-leave-to {
  transform: translateX(-300px);
}

.basket-indicator {
  @apply absolute text-tiny font-medium h-20 w-20 rounded-full bg-error text-grey200 flex justify-center items-center;

  padding-left: 1px;
  padding-bottom: 1px;
  top: -8px;
  right: -12px;
}

.header-link {
  white-space: nowrap;

  &:first-of-type {
    .submenu {
      @screen md {
        transform: translateX(-20%);
      }

      @screen xxl {
        transform: translateX(-50%);
      }
    }
  }
}

.header-link--children {
  &::after {
    content: '';
    @apply absolute bottom-0 h-2 bg-grey800 w-full left-0 opacity-0 transition-all duration-200 ease-in-out;
  }
  &:hover {
    .submenu {
      @apply opacity-100 pointer-events-auto;
    }
    &::after {
      @apply opacity-100;
    }
  }

  .submenu {
    .submenu-item {
      white-space: nowrap;
      @apply text-grey900;
    }
  }
}
.menu-hover-overlay {
  height: 100vh;
  z-index: -1;
  backdrop-filter: blur(2px);
}

.impersonator-tab {
  position: absolute;
  top: 0;
  right: 25px;
  background-color: #BB4824;
  border-radius: 0 0 15px 15px;
  z-index: 1000;
}

.fade-modal-enter-active {
transition: opacity 0.15s;
}
.fade-modal-leave-active {
transition: opacity 0.15s;
}
.fade-modal-enter-from,
.fade-modal-leave-to {
opacity: 0;
}
</style>

<template>
  <div ref="wysiwyg" :class="styleConfig" v-html="html"></div>
</template>

<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref, defineProps } from 'vue';

const props = defineProps<{
  html?: string,
  styleConfig?: Object
}>();

const emit = defineEmits<{
  (event: 'open-policy-modal'): void,
}>();

const wysiwyg = ref();

const isEditOrPreviewMode = computed(() => {
  const route = useRoute();
  return route.query.epieditmode === 'false' || route.query.epieditmode === 'true';
});

onMounted(() => {
  const el = wysiwyg.value;
  const anchorTags = el?.querySelectorAll('a') || [];
  anchorTags.forEach((tag) => {
    // Remove the preview_token from href if not in edit or preview mode
    const href = tag.getAttribute('href');
    if (!isEditOrPreviewMode.value && href && href.includes('preview')) {
      let newHref = href.replace(/[?&]preview_token=[^&]*/, '');
      newHref = newHref.replace(/[?&]language=[^&]*/, '');
      newHref = newHref.replace(/&$/, '');
      tag.setAttribute('href', newHref.replace(/&/, '?'));
    }

    if (tag.target === '_blank') {
      tag.setAttribute('rel', 'noopener');
    }
    if (tag.href.includes('.pdf')) {
      tag.setAttribute('download', 'download');
      tag.setAttribute('target', '_blank');
      tag.setAttribute('rel', 'noopener');
    }
    if (tag.id === 'openPolicyModal') {
      tag.addEventListener('click', openModal);
    }
  });
});

onBeforeUnmount(() => {
  const el = wysiwyg.value;

  const anchorTags = el?.querySelectorAll('a') || [];
  anchorTags.forEach((tag) => {
    tag.removeEventListener('click', openModal);
  });
});

function openModal(e: MouseEvent) {
  e.preventDefault();
  emit('open-policy-modal');
}

</script>

import { useGql } from '#imports'
import { getSdk as defaultGqlSdk } from '#gql/default'
export const GqlSdks = {
  default: defaultGqlSdk,
}
export const GqClientOps = {"default":["Articles","BrandPage","NotFoundPage","SideBarBlocks","Start","Content","ItemsInContentArea","AccordionBlock","ArticleTeaserBlock","BrandListingBlock","ContactUsCategoryBlock","CookieDeclarationBlock","CountDownTimerCoverBlock","CountDownTimerPromoBlock","Cover2GridBlock","Cover3GridBlock","CoverBlock","FormContainerBlock","GetSampleBlock","IconBlock","IconItemBlock","ImageBannerPromoBlock","NewsletterBlock","ProductListFilterBlock","ProductPromo2GridBlock","ProductPromoBlock","ProductsBlock","PromoBlock","QuickSelectionBlock","SEOTextBlock","SampleProductsBlock","TakeOverBlock","TextCardsBlock","TextImageVideo2GridBlock","TextImageVideoBlock","WarningBlock","WysiwygBlock","AgeVerificationPage","ArticleListingPage","ArticlePage","AutoshipProductListingPage","CampaignPage","CheckoutConfirmationPage","CheckoutPage","ComplaintPage","ContactUsPage","CreateAccountPage","ForgotPasswordPage","FreeSamplePage","GeofenceRedirectPage","InformationPage","MarketLandingPage","MyPage","ProductListingPage","ProductPage","ResetPasswordPage","SearchResultsPage","SidebarBlockPage","SignInPage","StandardBlockPage","StartPage","StoreLocatorPage","TrackingPage"]}
export const GqlArticles = (...params) => useGql()('Articles', ...params)
export const GqlBrandPage = (...params) => useGql()('BrandPage', ...params)
export const GqlNotFoundPage = (...params) => useGql()('NotFoundPage', ...params)
export const GqlSideBarBlocks = (...params) => useGql()('SideBarBlocks', ...params)
export const GqlStart = (...params) => useGql()('Start', ...params)
<template>
  <StepWrapper
    :heading="$lang('complaintResources', 'stepThree')"
    :loading="false"
    :step-activated="stepActivated"
    :current-step="activeIndex === stepIndex"
    @on-next="onNext"
    @edit-step="$emit('edit-step', stepIndex)"
  >
    <div>
      <img
        src="~assets/images/batch-complaint.jpg"
        class="w-full max-h-300 object-cover"
      />
      <div class="border border-grey600 rounded bg-grey200 mt-24">
        <TextInput
          :id="'complaintBatchNumber'"
          :placeholder="$lang('complaintResources', 'batchNumberPlaceholder')"
          :form-input="true"
          :error="inputError"
          :init-value="input"
          @submitInput="onInput"
        />
      </div>
    </div>
  </StepWrapper>
</template>
<script lang="ts">
import StepWrapper from './StepWrapper.vue';
import TextInput from '../form-elements/TextInput.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  components: { StepWrapper },
  props: {
    activeIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stepActivated: false,
      stepIndex: 2,
      input: '',
      inputError: '',
    };
  },
  watch: {
    activeIndex() {
      if (this.stepIndex === this.activeIndex) {
        this.stepActivated = true;
      }
    },
  },
  methods: {
    onNext() {
      if (/^\d{5}[ a-zA-Z]{0,1}\d{3,4}[-]{0,1}\d{0,1}$/.test(this.input)) {
        this.$emit('on-next', {
          batchNumber: this.input,
        });
      } else {
        this.inputError = this.$lang(
          'complaintResources',
          'genericValidationError'
        );
      }
    },
    onInput(input: string) {
      this.input = input;
      this.inputError = '';
    },
  },
});
</script>

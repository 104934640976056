<template>
  <div class="layout-container">
    <div
      class="flex justify-center py-12 meta-info-text relative min-h-40 md:px-48"
    >
      <div
        v-for="(usp, index) in usps"
        :key="usp.id"
        class="px-20 text-center text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 w-full md:w-auto opacity-0 md:opacity-100 md:static md:translate-x-0 md:translate-y-0"
        :class="{ active: checkActiveUsp(index) && (isTablet || isMobile) }"
      >
        <img
          src="~/assets/icons/check-solid-white.svg"
          class="h-12 w-12 mr-8 inline-block mb-2"
          alt="checkmark"
        />
        {{ usp }}
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    usps: {
      type: Array,
      required: true,
    },
    isMobile: {
      type: Boolean,
      required: true,
    },
    isTablet: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      activeUsp: 0,
      activeUspTimerMs: 2500,
    };
  },
  mounted() {
    if (this.usps.length > 1) {
      setInterval(this.activeUspTimerTick, this.activeUspTimerMs);
    }
  },
  methods: {
    checkActiveUsp(index: Number) {
      return index === this.activeUsp;
    },
    activeUspTimerTick() {
      this.activeUsp++;
      if (this.activeUsp >= this.usps.length) {
        this.activeUsp = 0;
      }
    },
  },
});
</script>
<style scoped>
.active {
  animation: fadeInOut ease-in-out 2.5s forwards;
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  85% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
</style>

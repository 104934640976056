<template>
  <div>
    <div
      class="flex justify-between sub-heading-lg-text text-[24px] leading-[34px] cursor-pointer select-none font-graphik"
    >
      <nuxt-link
        v-if="menuItem.href"
        :to="menuItem.href"
        :title="menuItem.text"
        @click="$emit('close-overlay')"
      >
        {{ menuItem.text }}
      </nuxt-link>
      <div v-else @click="toggleOpen">
        {{ menuItem.text }}
      </div>
      <div v-if="menuItem.children" @click="toggleOpen">
        <ClientOnly>
          <fa v-if="isOpen" :icon="['fal', 'minus']" />
          <fa v-else :icon="['fal', 'plus']" />
        </ClientOnly>
      </div>
    </div>
    <transition name="fade">
      <div v-if="menuItem.children && isOpen" class="mt-20">
        <div
          v-for="menuChildren in menuItem.children"
          :key="menuChildren.id"
          class="mb-20"
        >
          <div
            v-if="menuChildren.heading"
            class="text-xxs uppercase mb-8 leading-xxs font-medium font-calibre"
          >
            {{ menuChildren.heading }}
          </div>
          <nuxt-link
            v-for="child in menuChildren.links"
            :key="child.id"
            :to="child.href"
            :title="child.text"
            class="block font-medium mb-8 text-sm leading-base font-graphik"
          >
            {{ child.text }}
          </nuxt-link>
        </div>
      </div>
    </transition>
  </div>
</template>
<script lang="ts">
import { type PropType } from 'vue';
import type { ILinkItem } from '~/models/api-types';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    isMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    menuItem: {
      type: Object as PropType<ILinkItem[]>,
      required: true,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    toggleOpen() {
      if (this.isOpen) {
        this.isOpen = false;
      } else {
        this.isOpen = true;
      }
      /* if (this.isMobile) {
        this.$emit('toggleMenuRow', this.id);
      } */
    },
  },
});
</script>

<template>
  <div
    v-if="siteMessage && !siteMessageCookie"
    class="site-message p-8 md:pr-8 flex items-center justify-center text-center text-grey200 meta-info-text relative min-h-40 md:px-48"
    :style="{ background: backgroundColor }"
    :class="{ 'pr-32 flex-col sm:flex-row': siteMessage.isCountDownTimer, 'rounded': rounded }"
  >
    <WysiwygWrapper
      :style-config="{ 'small-base-text ml-0 px-32 md:px-0': true }"
      :html="siteMessage.mainBody"
    ></WysiwygWrapper>
    <span v-if="siteMessage.isCountDownTimer" class="ml-4">
      {{ $lang('sharedResources', 'daysShort') }}{{ cutOffCounter.days }}
      {{ $lang('sharedResources', 'hoursShort') }}{{ cutOffCounter.hours }}
      {{ $lang('sharedResources', 'minShort') }}{{ cutOffCounter.minutes }}
      {{ $lang('sharedResources', 'secondsShort') }}{{ cutOffCounter.seconds }}
      {{ cutOffCounter.days }}
    </span>

    <ClientOnly>
      <fa
        :icon="['fas', 'times']"
        class="text-base text-grey200 ml-auto mr-12 sm:mr-24 absolute right-0 top-0 mt-10 cursor-pointer"
        @click="closeMessage()"
      ></fa>
    </ClientOnly>
  </div>
</template>
<script lang="ts">
import type { PropType } from 'vue';
import type { ISiteMessage } from '~/models/api-types';
import { defineComponent } from 'vue';
export default defineComponent({
  props: {
    siteMessage: {
      type: Object as PropType<ISiteMessage>,
      required: true,
    },
    rounded: {
      type: Boolean,
      required: false
    }
  },
  data() {
    return {
      currentTime: new Date(),
      stopDate: null as any,
      hideCounter: true,
      siteMessageCookie: '' as string | null | undefined,
    };
  },
  computed: {
    backgroundColor(): string {
      return this.siteMessage.backgroundColor?.value ?? 'black';
    },
    cutOffCounter(): {
      days: number | string;
      hours: number | string;
      minutes: number | string;
      seconds: number | string;
    } {
      const dayjs = useDayjs();
      const currentTimeMoment = dayjs(this.currentTime);
      if (!this.stopDate) {
        return {
          days: '00',
          hours: '00',
          minutes: '00',
          seconds: '00',
        };
      }
      const diff = this.stopDate.diff(currentTimeMoment);
      const duration = dayjs.duration(diff);
      if (duration.asMilliseconds() > 0) {
        const daysDiff =
          duration.days() < 10
            ? `0${duration.days().toString()}`
            : duration.days();
        const hoursDiff =
          duration.hours() < 10
            ? `0${duration.hours().toString()}`
            : duration.hours();
        const minutesDiff =
          duration.minutes() < 10
            ? `0${duration.minutes().toString()}`
            : duration.minutes();
        const secondsDiff =
          duration.seconds() < 10
            ? `0${duration.seconds().toString()}`
            : duration.seconds();
        return {
          days: daysDiff,
          hours: hoursDiff,
          minutes: minutesDiff,
          seconds: secondsDiff,
        };
      } else {
        if (!this.siteMessage.cutOffDate) {
          this.setStopDate();
        }
        return {
          days: '00',
          hours: '00',
          minutes: '00',
          seconds: '00',
        };
      }
    },
  },
  created() {
    this.siteMessageCookie = useCookie('site-notice-' + this.siteMessage?.id, {
      maxAge: 3600 * 24 * 365,
    });
    const dayjs = useDayjs();

    if (this.siteMessage.isCountDownTimer) {
      const stopDate = this.setStopDate();
      const currentTime = dayjs(new Date());
      if (stopDate.diff(currentTime) > 0) {
        this.hideCounter = false;
        setInterval(this.updateCurrentTime.bind(this), 1000);
      }
    }
  },
  methods: {
    closeMessage() {
      this.siteMessageCookie = 'true';
    },
    updateCurrentTime() {
      this.currentTime = new Date();
    },
    setStopDate() {
      const currentTimeStamp = new Date();
      const dayjs = useDayjs();

      const date = this.siteMessage.cutOffDate
        ? this.siteMessage.cutOffDate
        : currentTimeStamp;

      const utcDate = dayjs.utc(date).toDate();
      const localDate = dayjs(utcDate).local().format('YYYY-MM-DD');

      const utcTime = dayjs.utc(this.siteMessage.cutOffTime).toDate();
      const localTime = dayjs(utcTime).local().format('HH:mm:ss');

      const completeLocalTime = dayjs(
        localDate + localTime,
        'YYYY-MM-DDHH:mm:ss'
      );

      const currentTimeMoment = dayjs(this.currentTime);

      const diff = completeLocalTime.diff(currentTimeMoment);

      if (diff < 0 && !this.siteMessage.cutOffDate) {
        const newDate = dayjs(new Date()).add(1, 'day');

        const newUtcDate = dayjs.utc(newDate).toDate();
        const newLocalDate = dayjs(newUtcDate)
          .local()
          .format('YYYY-MM-DD');

        const newCompleteLocalTime = dayjs(
          newLocalDate + localTime,
          'YYYY-MM-DDHH:mm:ss'
        );

        this.stopDate = newCompleteLocalTime;
        return newCompleteLocalTime;
      } else {
        this.stopDate = completeLocalTime;
        return completeLocalTime;
      }
    },
  },
});
</script>
<style scoped>
>>> a {
  @apply underline;
}
</style>

import { defineStore } from 'pinia';

export const useUiStore = defineStore('uiStore', {
  state: () => ({
    isMobile: true,
    isTablet: false,
    forceHideAgeGate: false,
  }),
  actions: {
    setIsMobile(payload: boolean) {
      this.isMobile = payload;
    },
    setIsTablet(payload: boolean) {
      this.isTablet = payload;
    },
    setForceHideAgeGate(payload: boolean) {
      this.forceHideAgeGate = payload;
    },
  }
});

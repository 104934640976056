<template>
  <StepWrapper
    :heading="$lang('complaintResources', 'stepTwo')"
    :loading="false"
    :step-activated="stepActivated"
    :current-step="activeIndex === stepIndex"
    @on-next="onNext"
    @edit-step="$emit('edit-step', stepIndex)"
  >
    <div>
      <img
        src="~assets/images/date-complaint.jpg"
        class="w-full max-h-300 object-cover"
      />
      <div class="border border-grey600 rounded bg-grey200 mt-24">
        <TextInput
          :id="'complaintManufacturingDate'"
          :placeholder="
            $lang('complaintResources', 'manufacturingDatePlaceholder')
          "
          :form-input="true"
          :error="manufacturingDateError"
          :init-value="manufacturingDateInput"
          @submitInput="onInputManufacturingDate"
        />
      </div>
      <div class="border border-grey600 rounded bg-grey200 mt-24">
        <TextInput
          :id="'complaintExpirationDate'"
          :placeholder="
            $lang('complaintResources', 'expirationDatePlaceholder')
          "
          :form-input="true"
          :error="expirationDateError"
          :init-value="expirationDateInput"
          @submitInput="onInputExpirationDate"
        />
      </div>
    </div>
  </StepWrapper>
</template>
<script lang="ts">
import StepWrapper from './StepWrapper.vue';
import TextInput from '../form-elements/TextInput.vue';
import { defineComponent } from 'vue';
export default defineComponent({
  components: { StepWrapper },
  props: {
    activeIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      stepActivated: false,
      stepIndex: 1,
      manufacturingDateError: '',
      manufacturingDateInput: '',
      expirationDateError: '',
      expirationDateInput: '',
    };
  },
  watch: {
    activeIndex() {
      if (this.stepIndex === this.activeIndex) {
        this.stepActivated = true;
      }
    },
  },
  methods: {
    onNext() {
      const dayjs = useDayjs();
      const manufacturingDateError = /^\d{2}[a-zA-Z]{3}\d{4}$/.test(
        this.manufacturingDateInput
      )
        ? ''
        : this.$lang('complaintResources', 'genericValidationError');
      const expirationDateError = /^\d{2}[a-zA-Z]{3}\d{4}$/.test(
        this.expirationDateInput
      )
        ? ''
        : this.$lang('complaintResources', 'genericValidationError');

      if (manufacturingDateError || expirationDateError) {
        this.manufacturingDateError = manufacturingDateError;
        this.expirationDateError = expirationDateError;
        return;
      }

      this.$emit('on-next', {
        manufacturingDate: dayjs(this.manufacturingDateInput).toISOString(),
        expirationDate: dayjs(this.expirationDateInput).toISOString(),
      });
    },
    onInputManufacturingDate(input: string) {
      this.manufacturingDateInput = input;
      this.manufacturingDateError = '';
    },
    onInputExpirationDate(input: string) {
      this.expirationDateInput = input;
      this.expirationDateError = '';
    },
  },
});
</script>

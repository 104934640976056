import { useGlobalContentStore } from '~/store/globalContent';

export default defineNuxtPlugin(() => {
  const config = useGlobalContentStore().config;
  return {
    provide: {
      lang: (langResource: string, langStr: string) => {
        if (
          config.langResources &&
          config.langResources[langResource] &&
          config.langResources[langResource][langStr]
        ) {
          return config.langResources[langResource][langStr];
        } else {
          return `${langResource} - ${langStr}`;
        }
      },
    },
  };
});


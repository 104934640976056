<template>
  <div
    class="alert-bar p-16 flex text-grey200 md:base-text small-base-regular-text"
    :class="{
      'bg-success': alertType === 'success',
      'bg-error': alertType === 'error',
      'bg-grey600 alert-info': alertType === 'info',
      rounded: roundedNone === false,
    }"
  >
    <fa
      v-if="config.theme === 'thunder' && alertType === 'error'"
      :icon="['fas', 'exclamation-triangle']"
      class="text-micro text-white w-24 h-24 mt-6 mr-8"
    ></fa>
    <div
      v-else-if="alertType === 'success' || alertType === 'error'"
      class="mr-8 bg-grey200 rounded-full flex justify-center items-center w-24 h-24 flex-shrink-0"
    >
      <fa
        :icon="['fas', alertType === 'error' ? 'times' : 'check']"
        class="text-micro"
        :class="{
          'text-success': alertType === 'success',
          'text-error': alertType === 'error',
        }"
      ></fa>
    </div>
    <fa
      v-else-if="config.theme == 'thunder'"
      :icon="['fas', 'info-circle']"
      class="text-base text-grey200 mr-8 mt-2"
    ></fa>
    <fa
      v-else=""
      :icon="['fal', 'info-circle']"
      class="text-base text-grey200 mr-8 mt-2"
    ></fa>
    <span v-html="text" />
  </div>
</template>
<script setup lang="ts">
const config = useGlobalContentStore().config;
import { useGlobalContentStore } from '~/store/globalContent';

const props = defineProps<{
  text: string,
  alertType: String,
  roundedNone?: boolean
}>();
</script>
<style scoped>
.text-micro {
  font-size: 12px;
}
</style>
